import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import AppIcon from '../../../components/icons';

const column1 = [
	{
		title: 'Scalable Architecture',
		description:
			'Our platform grows with your business, supporting smooth scaling without costly overhauls. Avoid the limitations of outdated, rigid software with our adaptable solutions.',
		icon: 'ChartBarIcon',
		bgClass: 'bg-primary bg-opacity-10',
		textClass: 'text-primary',
	},
	{
		title: 'Advanced Security',
		description:
			'Protect your data with our robust encryption, multi-factor authentication, and compliance with industry security standards. Designed for peace of mind in data-sensitive industries.',
		icon: 'ShieldCheckIcon',
		bgClass: 'bg-danger bg-opacity-10',
		textClass: 'text-danger',
	},
	{
		title: 'Comprehensive Services',
		description:
			'From consulting to deployment, we provide end-to-end services to ensure the successful implementation and operation of your business solutions.',
		icon: 'BriefcaseIcon',
		bgClass: 'bg-secondary bg-opacity-10',
		textClass: 'text-secondary',
	},
];

const column2 = [
	{
		title: 'Customization',
		description:
			'Adapt our platforms to fit your workflows without requiring extensive technical expertise. Customize features to enhance operational flexibility and efficiency.',
		icon: 'CogIcon',
		bgClass: 'bg-success bg-opacity-10',
		textClass: 'text-success',
	},
	{
		title: 'Seamless Data Migration',
		description:
			'Simplify the data migration process with tools and support that minimize downtime, prevent data loss, and ensure a smooth transition to our platforms.',
		icon: 'CircleStackIcon',
		bgClass: 'bg-info bg-opacity-10',
		textClass: 'text-info',
	},
	{
		title: 'Dedicated Support',
		description:
			'Our support team is available 24/7 to assist with any issues or inquiries, ensuring uninterrupted operations and peace of mind.',
		icon: 'LifebuoyIcon',
		bgClass: 'bg-warning bg-opacity-10',
		textClass: 'text-warning',
	},
];

const AreaOfExpertise: React.FC = () => {
	return (
		<section className="section-full bg-light">
			<Container>
				<Row>
					<Col lg={4} md={8} sm={12}>
						<div className="mb-6 mb-lg-0">
							<div className="section-title">
								<small className="title-small">Expertise</small>
								<h2 className="title-text">Our Core Areas of Expertise</h2>
								<p className="title-desc-text">
									We address the most pressing challenges faced by businesses today, including scalability, security,
									and operational inefficiencies. Our solutions empower organizations with innovative, secure, and
									user-friendly platforms that adapt to their evolving needs.
								</p>
							</div>
						</div>
					</Col>
					<Col lg={{ span: 7, offset: 1 }} sm={12}>
						<Row>
							<Col md={6}>
								{column1.map((area, index) => (
									<Card className={`${area.bgClass} border-0 mb-4 expertise-area-items`} key={index}>
										<Card.Body>
											<div className="mb-3 d-flex gap-2 align-items-center">
												<span className={`icon-wrap ${area.textClass}`}>
													<AppIcon name={area.icon} width={24} height={24} />
												</span>
												<Card.Title className="mb-0 expertise-item-title">{area.title}</Card.Title>
											</div>
											<Card.Text className="mb-0 expertise-area-desc">{area.description}</Card.Text>
										</Card.Body>
									</Card>
								))}
							</Col>
							<Col md={6} className="mt-md-5">
								{column2.map((area, index) => (
									<Card className={`${area.bgClass} border-0 mb-4 expertise-area-items`} key={index}>
										<Card.Body>
											<div className="mb-3 d-flex gap-2 align-items-center">
												<span className={`icon-wrap ${area.textClass}`}>
													<AppIcon name={area.icon} width={24} height={24} />
												</span>
												<Card.Title className="mb-0 expertise-item-title">{area.title}</Card.Title>
											</div>
											<Card.Text className="mb-0 expertise-area-desc">{area.description}</Card.Text>
										</Card.Body>
									</Card>
								))}
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default AreaOfExpertise;
