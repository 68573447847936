import { Link } from 'react-router-dom';
import styles from './error.module.scss';
const ErrorPage = () => {
	return (
		<div className={styles.errorPageWrap}>
			<div className={styles.errorPageContent}>
				<h2 className="text-4xl font-bold">Oops!</h2>
				<h4 className="text-xl font-bold">Page not found</h4>
				<p>The page you are looking might have been removed or it's temporarily unavailable.</p>
				<Link to="/" className="btn btn-lg">
					Go to dashboard
				</Link>
			</div>
		</div>
	);
};

export default ErrorPage;
