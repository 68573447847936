export interface Children {
	id: string;
	title: string;
	icon?: string;
	url: string;
}

export interface MenuItems {
	id: string;
	title: string;
	icon?: string;
	url?: string;
	children?: Children[];
}

const getMenuItems = (t: (key: string) => string): MenuItems[] => {
	const menuItems: MenuItems[] = [
		{ id: 'whatWeDo', title: 'What we do', url: '/' },
		{ id: 'whoWeAre', title: 'Who we are', url: '/who-we-are' },
		{ id: 'whatWeThink', title: 'What we think', url: '/what-we-think' },
		{ id: 'helpAndSupport', title: 'Help & support', url: '/help-and-support' },
	];
	return menuItems;
};

export default getMenuItems;
