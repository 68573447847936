import React from 'react';
import { useNavigate } from 'react-router-dom';
import HerosectionImage from '../../../assets/images/anantixia-hero.webp';
import CustomButton from '../../elements/button';

const HeroSection: React.FC = () => {
	const navigate = useNavigate();

	return (
		<section className="hero-section-wrapper">
			<div className="container">
				<div className="row align-items-center justify-content-lg-start justify-content-center flex-lg-nowrap">
					<div className="col-lg-6 col-12 order-lg-2 order-1">
						<div className="hero-section-text text-center text-lg-start">
							<div className="mb-lg-5 mb-3">
								<h1 className="mb-3">Revolutionize Your Organization with our Solutions.</h1>
								<p className="mb-0">
									Empowering organizations with cutting-edge, customizable software to streamline workflows, enhance
									collaboration, and ensure top-tier data security.
								</p>
							</div>
							<div data-cues="slideInDown">
								<CustomButton
									customSize="md"
									customVariant="primary"
									onClick={() => navigate('/help-and-support')}
									className='hero-section-btn'
								>
									Contact Us
								</CustomButton>
							</div>
						</div>
					</div>
					<div className="offset-lg-2 order-lg-2 col-lg-4 col-12 mt-3 mt-lg-0">
						<div className="hero-banner-image position-relative">
							<figure>
								<img src={HerosectionImage} alt="Anantixia" className="w-100 rounded-2" />
							</figure>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default HeroSection;
