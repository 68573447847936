import axios from 'axios';

// Determine which base URL to use based on the environment
const baseURL =
	process.env.NODE_ENV === 'production' ? process.env.REACT_APP_BASE_API_URL : process.env.REACT_APP_BASE_API_URL;
axios.defaults.baseURL = baseURL;
axios.interceptors.request.use(
	(config) => {
		config.headers['Authorization'] = 'Bearer ' + '';
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);
