import React, { useState } from 'react';
import { Container, Nav, Navbar, Offcanvas } from 'react-bootstrap';
import LogoImage from '../../../assets/images/logo-web.svg';
import AppSwitcher from '../../app-switcher';
import CustomButton from '../../elements/button';
import AppIcon from '../../icons';
import NavMenu from './nav-menu';

const Header: React.FC = () => {
	const [isNavOpen, setIsNavOpen] = useState(false);

	const ourApps = [
		{ name: 'AcademicCapIcon', title: 'Education' },
		{ name: 'ShoppingBagIcon', title: 'E-Commerce' },
		{ name: 'HeartIcon', title: 'Healthcare' },
		{ name: 'CurrencyDollarIcon', title: 'Finance' },
		{ name: 'TruckIcon', title: 'Logistics' },
		{ name: 'BuildingOfficeIcon', title: 'Hospitality' },
		{ name: 'BriefcaseIcon', title: 'Employee Management' },
		{ name: 'ComputerDesktopIcon', title: 'Software Development' },
	] as const;

	const toggleNav = () => setIsNavOpen((prev) => !prev);
	const closeNav = () => setIsNavOpen(false);

	return (
		<>
			<header>
				<div className="app-header">
					<Navbar expand="md" className="app-navbar">
						<Container>
							<div className="d-flex gap-2 lg-justify-content-between w-100 align-items-center">
								<Navbar.Toggle aria-controls="basic-navbar-nav" onClick={toggleNav}>
									<AppIcon name="Bars3Icon" width={24} height={24} />
								</Navbar.Toggle>
								<Navbar.Brand href="/" className="logo-wrap">
									<img src={LogoImage} alt="ManageFox" width={160} height={32} />
								</Navbar.Brand>
								<div className="ms-auto nav-action-wrap d-lg-none d-md-none">
									<AppSwitcher apps={ourApps} />
								</div>

								{/* desktop code */}
								<Navbar.Collapse className="d-none d-lg-flex navbar-wrap" id="basic-navbar-nav">
									<NavMenu />
									<div className="nav-action-wrap d-none d-md-flex">
										<AppSwitcher apps={ourApps} />
									</div>
								</Navbar.Collapse>

								{/* mobile code */}
								<Navbar.Offcanvas
									id="basic-navbar-nav"
									aria-labelledby="basic-navbar-nav"
									className="d-lg-none d-md-none custom-offcanvas"
									placement="start"
									show={isNavOpen}
									onHide={closeNav}
								>
									<Offcanvas.Header>
										<Offcanvas.Title>
											<Navbar.Brand href="/" className="logo-wrap">
												<img src={LogoImage} alt="ManageFox" width={183} height={30} />
											</Navbar.Brand>
										</Offcanvas.Title>
										<CustomButton onClick={closeNav}>
											<AppIcon name="XMarkIcon" width={24} height={24} />
										</CustomButton>
									</Offcanvas.Header>
									<Offcanvas.Body>
										<Nav className="justify-content-end flex-grow-1 pe-lg-3 pe-0">
											<NavMenu closeNav={closeNav} />
										</Nav>
									</Offcanvas.Body>
								</Navbar.Offcanvas>
							</div>
						</Container>
					</Navbar>
				</div>
			</header>
		</>
	);
};

export default Header;
