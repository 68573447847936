import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import AppIcon from '../../icons';

const IndustryExpertise: React.FC = () => {
	const industries = [
		{ name: 'AcademicCapIcon', title: 'Education', textColor: 'text-primary', bgClass: 'bg-primary' },
		{ name: 'ShoppingBagIcon', title: 'E-Commerce', textColor: 'text-success', bgClass: 'bg-success' },
		{ name: 'HeartIcon', title: 'Healthcare', textColor: 'text-danger', bgClass: 'bg-danger' },
		{ name: 'CurrencyDollarIcon', title: 'Finance', textColor: 'text-info', bgClass: 'bg-info' },
		{ name: 'TruckIcon', title: 'Logistics', textColor: 'text-warning', bgClass: 'bg-warning' },
		{ name: 'BuildingOfficeIcon', title: 'Hospitality', textColor: 'text-dark', bgClass: 'bg-dark' },
		{ name: 'BriefcaseIcon', title: 'Employee Management', textColor: 'text-primary', bgClass: 'bg-primary' },
		{
			name: 'ComputerDesktopIcon',
			title: 'Software Development',
			textColor: 'text-secondary',
			bgClass: 'bg-secondary',
		},
	] as const;
	return (
		<>
			<section className="section-full industry-expertise-section">
				<Container>
					<Row className="justify-content-md-center">
						<Col xs lg="8">
							<div className="section-title text-center">
								<small className="title-small">Industries We Empower</small>
								<h2 className="title-text">Revolutionizing Industries with Scalable Solutions</h2>
								<p className="title-desc-text">
									Our platform is designed to address the unique challenges of diverse industries. From Education and
									Healthcare to Logistics and Finance, our microservices-based architecture ensures scalability,
									security, and efficiency across all sectors.
								</p>
							</div>
						</Col>
					</Row>
					<Row className="g-4">
						{industries.map((industry, index) => (
							<Col key={index} lg={3} md={4} xs={6}>
								<div
									className={`card text-center px-4 py-5 rounded h-full expertise-item bg-opacity-10 ${industry.bgClass}`}
								>
									<AppIcon
										name={industry.name}
										width={44}
										height={44}
										className={`mx-auto mb-3 ${industry.textColor}`}
									/>
									<h4 className="mb-0 card-text fs-6 industry-title">{industry.title}</h4>
								</div>
							</Col>
						))}
					</Row>
				</Container>
			</section>
		</>
	);
};

export default IndustryExpertise;
