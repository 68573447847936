import * as HeroIcons from '@heroicons/react/24/outline';
import React from 'react';

interface CustomIconProps {
	name: string;
	width?: number;
	height?: number;
	className?: string;
}

const AppIcon: React.FC<CustomIconProps> = ({ name, width = 24, height = 24, className }) => {
	const HeroIcon = HeroIcons[name as keyof typeof HeroIcons];

	if (!HeroIcon) {
		console.warn(`Icon ${name} not found.`);
		return null;
	}

	return <HeroIcon width={width} height={height} className={className} />;
};

export default AppIcon;
