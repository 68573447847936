import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import AppIcon from '../../../components/icons';

const features = [
	{
		iconName: 'ChartBarIcon',
		title: 'Scalable Architecture',
		description:
			'Our platform grows with your business, enabling seamless scaling without costly disruptions or upgrades.',
	},
	{
		iconName: 'ShieldCheckIcon',
		title: 'Advanced Security',
		description:
			'Top-notch data protection with encryption, multi-factor authentication, and adherence to global security standards.',
	},
	{
		iconName: 'LightBulbIcon',
		title: 'AI-Driven Insights',
		description:
			'Leverage predictive analytics and automation to make smarter, faster decisions and foster innovation.',
	},
	{
		iconName: 'CogIcon',
		title: 'Comprehensive Services',
		description:
			'From planning to implementation, we offer end-to-end solutions tailored to your business requirements.',
	},
	{
		iconName: 'ArrowsPointingInIcon',
		title: 'Unified Platforms',
		description:
			'Bring all your tools and data into one centralized platform for seamless collaboration and improved efficiency.',
	},
	{
		iconName: 'PhoneIcon',
		title: '24/7 Support',
		description:
			'Our dedicated support team is available around the clock to ensure uninterrupted operations and peace of mind.',
	},
];

const WhyChooseUs: React.FC = () => {
	return (
		<>
			<section className="section-full bg-light">
				<Container>
					<Row className="justify-content-md-center">
						<Col xs lg="8">
							<div className="section-title text-center">
								<small className="title-small">Why Choose US</small>
								<h2 className="title-text">Discover What Makes Anantixia Unique</h2>
								<p className="title-desc-text">
									We offer a unique combination of scalability, security, and cutting-edge AI solutions to simplify and
									enhance your business operations.
								</p>
							</div>
						</Col>
					</Row>
					<Row className="mt-4">
						{features.map((feature, index) => (
							<Col key={index} lg={4} md={6}>
								<div className="mb-lg-5 mb-4">
									<div className="d-flex gap-3 mb-lg-3 mb-2 align-items-center">
										<span className="services-icon-wrap text-primary">
											<AppIcon name={feature.iconName} width={18} height={18} />
										</span>
										<h5 className="mb-0">{feature.title}</h5>
									</div>
									<div>
										<p className="mb-0 text-base">{feature.description}</p>
									</div>
								</div>
							</Col>
						))}
					</Row>
				</Container>
			</section>
		</>
	);
};

export default WhyChooseUs;
