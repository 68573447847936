import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import AppIcon from '../icons';

interface AppSwitcherProps {
	apps: readonly { name: string; title: string }[];
}

const AppSwitcher: React.FC<AppSwitcherProps> = ({ apps }) => {
	const CustomToggle = React.forwardRef<
		HTMLButtonElement,
		{ children: React.ReactNode; onClick: (event: React.MouseEvent<HTMLButtonElement>) => void }
	>(({ children, onClick }, ref) => (
		<button
			type="button"
			ref={ref}
			onClick={(e) => {
				e.preventDefault();
				onClick(e);
			}}
			className="app-switcher-btn-header"
		>
			{children}
		</button>
	));

	const CustomMenu = React.forwardRef<
		HTMLDivElement,
		{
			style: React.CSSProperties;
			className: string;
			'aria-labelledby': string;
			children?: React.ReactNode;
			apps: readonly { name: string; title: string }[];
		}
	>(({ style, className, 'aria-labelledby': labeledBy, apps }, ref) => (
		<div ref={ref} style={style} className={`${className} app-switcher-content`} aria-labelledby={labeledBy}>
			<h5 className="dropdown-title">Our Solutions</h5>
			<div className="apps-grid">
				{apps.map((app) => (
					<div className="app-box" key={app.name}>
						<AppIcon name={app.name} width={32} height={32} />
						<p className="app-title">{app.title}</p>
					</div>
				))}
			</div>
		</div>
	));

	return (
		<Dropdown>
			<Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
				<AppIcon name="Squares2X2Icon" width={16} height={16} />
			</Dropdown.Toggle>
			<Dropdown.Menu as={CustomMenu} apps={apps} />
		</Dropdown>
	);
};

export default AppSwitcher;
