import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { ThemeProvider } from '../contexts/theme-provider';
import Footer from './footer/footer';
import Header from './header/header';
import ScrollToTop from './scroll-to-top';

const AppLayout: React.FC = () => {
	const [isDark, setIsDark] = useState(false);

	useEffect(() => {
		if (isDark) {
			document.body.classList.add('dark');
		} else {
			document.body.classList.remove('dark');
		}
	}, [isDark]);

	return (
		<ThemeProvider>
			<div className="position-relative">
				<ScrollToTop />
				<Header />
				<div className="h-100 position-relative">
					<main className="mainContainer">
						<Outlet />
					</main>
					<div className="mt-auto">
						<Footer />
					</div>
				</div>
			</div>
		</ThemeProvider>
	);
};

export default AppLayout;
