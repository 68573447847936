import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import WhoWeAreImage from '../../assets/images/who-we-are/who-we-are.png';
import AppIcon from '../../components/icons';

const whoWeAreList = [
	{ text: 'Cutting-edge SaaS solutions tailored to diverse industries' },
	{ text: 'Scalable, secure, and user-friendly platforms' },
	{ text: 'Committed to innovation, quality, and client focus' },
];

const services = [
	{
		iconName: 'AcademicCapIcon',
		title: 'Education Management',
		description:
			'Empowering educational institutions with tools for streamlined operations, data management, and effective communication.',
	},
	{
		iconName: 'HeartIcon',
		title: 'Healthcare Solutions',
		description:
			'Advanced systems for hospitals and clinics to enhance patient care, manage medical records, and ensure operational efficiency.',
	},
	{
		iconName: 'ComputerDesktopIcon',
		title: 'IT Services',
		description:
			'Scalable IT solutions to drive digital transformation and meet the evolving needs of modern businesses.',
	},
	{
		iconName: 'BriefcaseIcon',
		title: 'Employee Management',
		description: 'Comprehensive tools for employee tracking, payroll management, and performance optimization.',
	},
	{
		iconName: 'BuildingOfficeIcon',
		title: 'Hospitality Management',
		description:
			'Robust platforms designed to simplify operations and enhance guest experiences in the hospitality sector.',
	},
	{
		iconName: 'TruckIcon',
		title: 'Logistics and Supply Chain',
		description: 'Integrated systems to optimize logistics, track inventory, and streamline supply chain operations.',
	},
];

const WhoWeAre: React.FC = () => {
	return (
		<>
			<section className="section-full">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-6 col-md-6 col-12 order-lg-1 order-2">
							<div className="section-title">
								<small className="title-small">Who we are</small>
								<h2 className="title-text">Driving Innovation Across Industries</h2>
								<p className="title-desc-text">
									At Anantixia, we leverage cutting-edge technologies like AI, microservices, and secure protocols to
									empower businesses with scalable, configurable, and user-friendly solutions.
								</p>
							</div>
							<ul className="list-unstyled mb-5">
								{whoWeAreList.map((item, index) => (
									<li className="mb-2 d-flex" key={index}>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="16"
											height="16"
											fill="currentColor"
											className="bi bi-check-circle text-primary mt-1"
											viewBox="0 0 16 16"
										>
											<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
											<path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
										</svg>
										<span className="ms-2">{item.text}</span>
									</li>
								))}
							</ul>
						</div>
						<div className="col-lg-4 offset-lg-1 col-md-6 col-12 order-1 order-lg-2">
							<div className="position-relative mb-lg-0">
								<figure className="mx-auto w-75 w-lg-full mb-5 mb-lg-0">
									<img src={WhoWeAreImage} alt="Who we are" className="img-fluid" />
								</figure>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="section-full bg-light">
				<Container>
					<Row className="justify-content-md-center">
						<Col xs lg="8">
							<div className="section-title text-center">
								<small className="title-small">Our Services</small>
								<h2 className="title-text">Empowering Your Business with Tailored Solutions</h2>
								<p className="title-desc-text">
									From education and healthcare to logistics and IT, our services are designed to simplify operations,
									boost productivity, and drive growth.
								</p>
							</div>
						</Col>
					</Row>
					<Row className="mt-4">
						{services.map((service, index) => (
							<Col key={index} lg={4} md={6}>
								<div className="mb-lg-5 mb-4">
									<div className="d-flex gap-3 mb-lg-3 mb-2 align-items-center">
										<span className="services-icon-wrap text-primary">
											<AppIcon name={service.iconName} width={18} height={18} />
										</span>
										<h5 className="mb-0">{service.title}</h5>
									</div>
									<div>
										<p className="mb-0 text-base">{service.description}</p>
									</div>
								</div>
							</Col>
						))}
					</Row>
				</Container>
			</section>
		</>
	);
};

export default WhoWeAre;
