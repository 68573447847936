import React, { ReactNode, createContext, useContext, useEffect, useRef, useState } from 'react';
import LoadingBar, { LoadingBarRef } from 'react-top-loading-bar';

interface LoaderContextType {
	loading: boolean;
	setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

interface LoadingProps {
	children: ReactNode;
}

const LoaderContext = createContext<LoaderContextType>({
	loading: false,
	setLoading: () => {},
});

export const useLoader = () => useContext(LoaderContext);

export const LoaderProvider: React.FC<LoadingProps> = React.memo(({ children }) => {
	const ref = useRef<LoadingBarRef>(null);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const loaderRef = ref.current;
		if (loaderRef) {
			if (loading) {
				loaderRef.continuousStart();
			} else {
				loaderRef.complete();
			}
		}
	}, [loading]);

	return (
		<LoaderContext.Provider value={{ loading, setLoading }}>
			<LoadingBar color="#1d7afd" ref={ref} />
			{children}
		</LoaderContext.Provider>
	);
});
