import emailjs from '@emailjs/browser';
import React, { useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import MapImageContact from '../../../assets/images/contact-map.png';
import CustomButton from '../../../components/elements/button';
import EmailLink from '../../../components/elements/email-link';
import PhoneLink from '../../../components/elements/phone-link';
import AppIcon from '../../../components/icons';

const Contact: React.FC = () => {
	const [formData, setFormData] = useState({
		firstName: '',
		lastName: '',
		email: '',
		phone: '',
		organisation: '',
		message: '',
	});

	const [submitted, setSubmitted] = useState(false);
	const [error, setError] = useState('');

	const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({ ...prevData, [name]: value }));
	};

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		setError('');

		const from_name = `${formData.firstName} ${formData.lastName}`.trim();

		// Use EmailJS to send the email
		console.log(
			'EMail js details',
			process.env.REACT_APP_EMAILJS_SERVICE_ID,
			process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
			process.env.REACT_APP_EMAILJS_PUBLIC_KEY
		);
		emailjs
			.send(
				process.env.REACT_APP_EMAILJS_SERVICE_ID as string, // Replace with your EmailJS Service ID
				process.env.REACT_APP_EMAILJS_TEMPLATE_ID as string, // Replace with your EmailJS Template ID
				{
					from_name,
					email: formData.email,
					phone: formData.phone,
					organisation: formData.organisation,
					message: formData.message,
				},
				process.env.REACT_APP_EMAILJS_PUBLIC_KEY as string // Replace with your EmailJS Public Key
			)
			.then(
				(response) => {
					console.log('Email sent successfully:', response.text);
					setSubmitted(true);
				},
				(err) => {
					console.error('Error sending email:', err);
					setError('Failed to send the email. Please try again.');
				}
			);
	};

	return (
		<section className="section-full">
			<Container>
				<Row className="justify-content-md-center">
					<Col xs lg="9">
						<div className="section-title text-center">
							<small className="title-small">Help & Support</small>
							<h2 className="title-text">How can we help?</h2>
							<p className="title-desc-text">Please fill out the form below, and we will get in touch shortly.</p>
						</div>
						<Row>
							<Col sm="7">
								{submitted ? (
									<div className="email-success-wrapper">
										<h3 className="text-success">
											<AppIcon name="CheckBadgeIcon" className="text-success" width={32} height={32} />
											Thank You!
										</h3>
										<h5>Your message has been successfully sent.</h5>
										<p>
											Our team will review your query and get back to you as soon as possible. In the meantime, feel
											free to explore our website or contact us directly if you have any urgent concerns.
										</p>
										<p>We appreciate your trust in us!</p>
									</div>
								) : (
									<Form onSubmit={handleSubmit}>
										<Row>
											<Col sm={6}>
												<Form.Group className="custom-form-group">
													<Form.Label htmlFor="firstName">First Name</Form.Label>
													<Form.Control
														className="custom-form-control"
														type="text"
														placeholder="First name"
														id="firstName"
														name="firstName"
														value={formData.firstName}
														onChange={handleChange}
														required
													/>
												</Form.Group>
											</Col>
											<Col sm={6}>
												<Form.Group className="custom-form-group">
													<Form.Label htmlFor="lastName">Last Name</Form.Label>
													<Form.Control
														className="custom-form-control"
														type="text"
														placeholder="Last name"
														id="lastName"
														name="lastName"
														value={formData.lastName}
														onChange={handleChange}
														required
													/>
												</Form.Group>
											</Col>
										</Row>
										<Row>
											<Col sm={6}>
												<Form.Group className="custom-form-group">
													<Form.Label htmlFor="email">Email</Form.Label>
													<Form.Control
														className="custom-form-control"
														type="email"
														placeholder="Email"
														id="email"
														name="email"
														value={formData.email}
														onChange={handleChange}
														required
													/>
												</Form.Group>
											</Col>
											<Col sm={6}>
												<Form.Group className="custom-form-group">
													<Form.Label htmlFor="phone">Phone</Form.Label>
													<Form.Control
														className="custom-form-control"
														type="text"
														placeholder="Phone"
														id="phone"
														name="phone"
														value={formData.phone}
														onChange={handleChange}
													/>
												</Form.Group>
											</Col>
										</Row>
										<Form.Group className="custom-form-group">
											<Form.Label htmlFor="organisation">Organisation</Form.Label>
											<Form.Control
												className="custom-form-control"
												type="text"
												placeholder="Organisation"
												id="organisation"
												name="organisation"
												value={formData.organisation}
												onChange={handleChange}
											/>
										</Form.Group>
										<Form.Group className="custom-form-group">
											<Form.Label htmlFor="message">Message</Form.Label>
											<Form.Control
												as="textarea"
												className="custom-form-control"
												placeholder="Your message"
												id="message"
												name="message"
												rows={4}
												value={formData.message}
												onChange={handleChange}
												required
											/>
										</Form.Group>
										{error && <div className="text-danger">{error}</div>}
										<CustomButton type="submit" className="w-100" customVariant="primary" customSize="sm">
											Submit
										</CustomButton>
									</Form>
								)}
							</Col>
							<Col sm="5">
								<div className="contact-address-wrapper">
									<img src={MapImageContact} alt="contact us" className="img-fluid mb-5 p-1 border rounded" />
									<h4 className="mb-3">Contact details</h4>
									<div className="d-flex mb-1">
										<div>
											<AppIcon name="MapPinIcon" width={16} height={16} />
										</div>
										<div className="ms-2">
											<p className="mb-0">
												845/7 Urban Enclave, Sector 7,
												<br /> Gurgaon - 122001,
												<br /> Haryana, India
											</p>
										</div>
									</div>
									<div className="d-flex mb-1`">
										<div>
											<AppIcon name="PhoneIcon" width={16} height={16} />
										</div>
										<div className="ms-2">
											<div className="mb-0 d-flex gap-2">
												<PhoneLink phoneNumber="+91 8745071220" phoneLinkText="+91 8745071220" /> |
												<PhoneLink phoneNumber="+91 9205384228" phoneLinkText="+91 9205384228" />
											</div>
										</div>
									</div>
									<div className="d-flex mb-3">
										<div>
											<AppIcon name="EnvelopeIcon" width={16} height={16} />
										</div>
										<div className="ms-2">
											<div className="d-flex gap-2 mb-0">
												<EmailLink emailId="info@anantixia.com" emailLinkText="info@anantixia.com" /> |
												<EmailLink emailId="support@anantixia.com" emailLinkText="support@anantixia.com" />
											</div>
										</div>
									</div>
								</div>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default Contact;
