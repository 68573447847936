import React from 'react';

interface EmailLinkProps {
	emailId: string;
	subject?: string;
	body?: string;
  emailLinkText?: string;
}

const EmailLink: React.FC<EmailLinkProps> = ({ emailId, subject = '', body = '', emailLinkText = 'Send Email' }) => {
	return (
		<div className='d-inline'>
			<a className='link-text' href={`mailto:${emailId}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`}>
				{emailLinkText}
			</a>
		</div>
	);
};

export default EmailLink;
