import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import './assets/scss/main.scss';
import AxiosInterceptorProvider from './components/contexts/interceptor/axiosInterceptor-provider';
import './components/contexts/interceptor/request-interceptor';
import { LoaderProvider } from './components/contexts/loader-context';
import { ToastProvider } from './components/contexts/toast-context';
import './i18n';
import routes from './routes';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<React.StrictMode>
		<React.Suspense>
			<AxiosInterceptorProvider>
				<LoaderProvider>
					<ToastProvider>
						<RouterProvider router={routes} fallbackElement={'Laoding...'} />
					</ToastProvider>
				</LoaderProvider>
			</AxiosInterceptorProvider>
		</React.Suspense>
	</React.StrictMode>
);
