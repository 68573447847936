import React, { forwardRef, useMemo } from 'react';
import classNames from 'classnames';
import { Button as BootstrapButton, ButtonProps } from 'react-bootstrap';

interface CustomButtonProps extends Omit<ButtonProps, 'variant' | 'size'> {
  classNames?: string | string[];
  customSize?: 'xs' | 'sm' | 'md' | 'lg' | '';
  customVariant?: 'primary' | 'secondary' | 'success' | 'danger' | 'link' | '';
  outlined?: boolean;
  children?: React.ReactNode;
}

const CustomButton = forwardRef<HTMLButtonElement, CustomButtonProps>(
  (
    {
      classNames: customClassNames,
      children,
      customSize,
      customVariant,
      outlined,
      className,
      ...rest
    },
    ref
  ) => {
    // Combine all classNames
    const combinedClassNames = useMemo(
      () =>
        classNames(
          'custom-button',
          customClassNames,
          `custom-button-${customSize}`,
          outlined ? `custom-button-outline-${customVariant}` : `custom-button-${customVariant}`,
          className // Append additional classes
        ),
      [customClassNames, customSize, customVariant, outlined, className]
    );

    return (
      <BootstrapButton ref={ref} className={combinedClassNames} {...rest}>
        {children}
      </BootstrapButton>
    );
  }
);

CustomButton.displayName = 'CustomButton'; // Helps with debugging in React DevTools

export default CustomButton;
