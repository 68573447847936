import { useTranslation } from 'react-i18next';
import HeroSection from './hero-section';
import IndustryExpertise from './industry-expertise';
import OurLeadership from './our-leadership';
import WhatWeDo from './what-we-do';
import WhyChooseUs from './why-choose-us';

const HomeInfo: React.FC = () => {
	const { t } = useTranslation();

	return (
		<>
			<HeroSection />
			<WhatWeDo />
			<IndustryExpertise />
			<WhyChooseUs />
			{/* <OurLeadership /> */}
		</>
	);
};
export default HomeInfo;
