import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import AppIcon from '../../icons';
import getMenuItems, { MenuItems } from '../menu/menu-items';
import { Nav } from 'react-bootstrap';

interface NavMenuProps {
	closeNav?: () => void;
}

const NavMenu: React.FC<NavMenuProps> = ({ closeNav }) => {
	const { t } = useTranslation();
	const menuItems = getMenuItems(t);
	const menuRef = useRef<HTMLDivElement>(null);
	const location = useLocation(); // Use the useLocation hook

	// Check if the menu item is active
	const isActive = (item: MenuItems): boolean => {
		if (item.url && location.pathname === item.url) {
			return true;
		}
		if (item.children) {
			return item.children.some((child) => location.pathname.startsWith(child.url));
		}
		return false;
	};

	console.log('close nav', closeNav);

	// Render menu items recursively
	const renderMenuItems = (items: MenuItems[]) => (
		<div className="user-menu-wrap" ref={menuRef}>
			{items.map((item) => (
				<div key={item.id} className={`menu-item ${isActive(item) ? 'active' : ''}`}>
					<Link to={item.url || '#'} className="menu-item-text" onClick={closeNav}>
						{item.title}
						{item.children && (
							<span className="dropdown-arrow-icon">
								<AppIcon name="ChevronDownIcon" width={16} height={16} />
							</span>
						)}
					</Link>
				</div>
			))}
		</div>
	);

	return <>{renderMenuItems(menuItems)}</>;
};

export default NavMenu;
