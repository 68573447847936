import React from 'react';
import { Col, Container, Row, Tab } from 'react-bootstrap';
import AiBanner from '../../assets/images/what-we-think/ai-banner.png';
import AppIcon from '../../components/icons';
import AreaOfExpertise from './area-of-expertise';

const WhatWeThink: React.FC = () => {
	const visionData = [
		{
			icon: 'LightBulbIcon',
			title: 'Our Core Values',
			description:
				'We are driven by Innovation, Quality, Client Focus, Security, and User Experience. Our solutions are designed to foster seamless operations, ensuring that organizations benefit from adaptable, secure, and user-friendly platforms tailored to their unique needs.',
		},
		{
			icon: 'ShieldCheckIcon',
			title: 'Advanced Security',
			description:
				'Our advanced security solutions employ strong encryption protocols, multi-factor authentication, and rigorous compliance with global standards. This ensures the utmost protection against cyber threats while maintaining the integrity and confidentiality of your sensitive data.',
		},
		{
			icon: 'ScaleIcon',
			title: 'Scalability',
			description:
				'Our scalable architecture enables businesses to grow effortlessly, adapting to new challenges and opportunities without disrupting ongoing operations. Whether expanding teams, adopting new workflows, or scaling up infrastructure, our solutions evolve with your needs.',
		},
	];

	const aiSolutionsData = [
		{
			icon: 'CloudIcon',
			title: 'Unified Platforms',
			description:
				'Eliminate inefficiencies with a centralized platform that integrates data, reduces silos, and enhances collaboration.',
		},
		{
			icon: 'KeyIcon',
			title: 'Seamless Data Migration',
			description:
				'Transition effortlessly to our systems with secure and risk-free data migration processes designed to minimize downtime and ensure integrity.',
		},
		{
			icon: 'ChartPieIcon',
			title: 'AI-Enabled Decision Making',
			description:
				'Enhance productivity and innovation by leveraging AI-driven tools for automation, predictive analytics, and informed decision-making.',
		},
	];

	return (
		<>
			<section className="section-full">
				<Container>
					<Row className="justify-content-md-center">
						<Col xs={12} lg={5} className="text-center">
							<div className="section-title">
								<small className="title-small">Our Vision</small>
								<h2 className="title-text">Empowering Organizations with Scalable SaaS Solutions</h2>
							</div>
						</Col>
					</Row>
					<Row className="mt-3">
						{visionData.map((item, index) => (
							<Col lg={4} md={6} key={index}>
								<div className="pe-lg-5 mb-lg-0 mb-4">
									<div className="d-flex gap-2 align-items-center mb-3">
										<div className="text-primary">
											<AppIcon name={item.icon} width={24} height={24} />
										</div>
										<h4 className="mb-0">{item.title}</h4>
									</div>
									<p className="mb-0">{item.description}</p>
								</div>
							</Col>
						))}
					</Row>
				</Container>
			</section>
			<AreaOfExpertise />
			<section className="section-full">
				<Container>
					<Row className="justify-content-md-center mb-4">
						<Col xs={12} lg={8} className="text-center">
							<div className="section-title">
								<h2 className="title-text">AI-Driven Solutions Across Industries</h2>
								<p className="title-desc-text">
									Leverage our cutting-edge IT solutions to enhance scalability, streamline operations, and make
									data-driven decisions with actionable insights powered by AI.
								</p>
							</div>
						</Col>
					</Row>
					<Tab.Container id="ai-enabled-tabs" defaultActiveKey="tab1">
						<Row className="align-items-center">
							<Col md={6} className="order-2 order-lg-1">
								<div className="flex-column justify-content-center align-items-center h-100 border-0 gap-4 px-lg-5">
									{aiSolutionsData.map((item, index) => (
										<div className="ai-driven-solutions px-lg-3 py-3" key={index}>
											<div className="d-flex gap-3">
												<div className="icon-wrap">
													<AppIcon name={item.icon} width={18} height={18} />
												</div>
												<div className="nav-text">
													<h5>{item.title}</h5>
													<p className="text-base mb-0">{item.description}</p>
												</div>
											</div>
										</div>
									))}
								</div>
							</Col>
							<Col md={{ span: 5 }} className="text-center order-1 order-lg-2 mb-5 mb-lg-0">
								<img src={AiBanner} alt="Unified Platforms" className="img-fluid w-lg-100 w-75 mx-auto rounded-4" />
							</Col>
						</Row>
					</Tab.Container>
				</Container>
			</section>
		</>
	);
};

export default WhatWeThink;
