// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error_errorPageWrap__kaCaW {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  position: relative;
  background-color: var(--bg-secondary-color);
}
.error_errorPageWrap__kaCaW .error_errorPageContent__45juw {
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 340px;
}
.error_errorPageWrap__kaCaW .error_errorPageContent__45juw h2 {
  margin-bottom: 16px;
  line-height: 120px;
}
.error_errorPageWrap__kaCaW .error_errorPageContent__45juw h4 {
  text-transform: uppercase;
  color: var(--subtitle-text-color);
}
.error_errorPageWrap__kaCaW .error_errorPageContent__45juw p {
  font-size: 14px;
}
.error_errorPageWrap__kaCaW .error_errorPageContent__45juw a {
  background-color: var(--bg-secondary-color);
  border: 1px solid var(--border-dark-color);
  color: var(--title-text-color);
  margin: 24px auto 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/error/error.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;EACA,WAAA;EACA,kBAAA;EACA,2CAAA;AACJ;AAAI;EACI,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,YAAA;AAER;AADQ;EACI,mBAAA;EACA,kBAAA;AAGZ;AADQ;EACI,yBAAA;EACA,iCAAA;AAGZ;AADQ;EACI,eAAA;AAGZ;AADQ;EACI,2CAAA;EACA,0CAAA;EACA,8BAAA;EACA,mBAAA;AAGZ","sourcesContent":[".errorPageWrap {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n    width: 100%;\n    position: relative;\n    background-color: var(--bg-secondary-color);\n    .errorPageContent {\n        text-align: center;\n        display: flex;\n        flex-direction: column;\n        width: 340px;\n        h2 {\n            margin-bottom: 16px;\n            line-height: 120px;\n        }\n        h4 {\n            text-transform: uppercase;\n            color: var(--subtitle-text-color);\n        }\n        p {\n            font-size: 14px;\n        }\n        a {\n            background-color: var(--bg-secondary-color);\n            border: 1px solid var(--border-dark-color);\n            color: var(--title-text-color);\n            margin: 24px auto 0;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorPageWrap": `error_errorPageWrap__kaCaW`,
	"errorPageContent": `error_errorPageContent__45juw`
};
export default ___CSS_LOADER_EXPORT___;
