import React from 'react';
import { Toast } from 'react-bootstrap';
import AppIcon from '../../icons';
import CustomButton from '../button';

// variants available: ['Primary', 'Secondary', 'Success', 'Danger', 'Warning', 'Info', 'Light', 'Dark'];
interface ToastAlertProps {
	text: string;
	show: boolean;
	onClose: () => void;
	variant?: string;
}

const ToastAlert: React.FC<ToastAlertProps> = ({ text, show, onClose, variant = 'Primary' }) => {
	return (
		<div className="toastAlertPosition">
			<Toast className="border-0 w-auto" show={show} delay={3000} autohide onClose={onClose} bg={variant.toLowerCase()}>
				<Toast.Body className="text-light d-flex justify-content-between gap-2">
					{text}
					<CustomButton onClick={onClose} outlined className={`closeCustomButtonToast text-light`}>
						<AppIcon name="XMarkIcon" width={24} height={24} />
					</CustomButton>
				</Toast.Body>
			</Toast>
		</div>
	);
};

export default ToastAlert;
