import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';

type Theme = 'light' | 'dark';

interface ThemeContextType {
	theme: Theme;
	toggleTheme: () => void;
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

interface ThemeProviderProps {
	children: ReactNode;
}

export function ThemeProvider({ children }: ThemeProviderProps) {
	const [theme, setTheme] = useState<Theme>('light');

	useEffect(() => {
		// Check the system theme preference
		const systemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';

		setTheme(systemTheme as Theme);
	}, []);

	const toggleTheme = () => {
		setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
	};

	if (theme === 'dark') {
		document.documentElement.classList.add('dark'); // Add 'dark' class to HTML element
		document.body.setAttribute('data-theme', 'dark');
	} else {
		document.documentElement.classList.remove('dark'); // Remove 'dark' class from HTML element
		document.body.setAttribute('data-theme', 'light');
	}

	const themeContextValue = useMemo(() => ({ theme, toggleTheme }), [theme, toggleTheme]);

	return <ThemeContext.Provider value={themeContextValue}>{children}</ThemeContext.Provider>;
}

export function useTheme() {
	const context = useContext(ThemeContext);
	if (!context) {
		throw new Error('useTheme must be used within a ThemeProvider');
	}
	return context;
}
