import React, { createContext, useContext, useState } from 'react';
import ToastAlert from '../../elements/toast-alert';

interface ToastContextType {
	showToast: (text: string, variant: string) => void;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

/**
 * @Variables
 * ['Primary', 'Secondary', 'Success', 'Danger', 'Warning', 'Info', 'Light', 'Dark'];
 */
export const useToast = () => {
	const context = useContext(ToastContext);
	if (!context) {
		throw new Error('useToast must be used within a ToastProvider');
	}
	return context;
};

export const ToastProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const [toast, setToast] = useState<{ text: string; variant: string } | null>(null);

	/**
	 * @Variables
	 * ['Primary', 'Secondary', 'Success', 'Danger', 'Warning', 'Info', 'Light', 'Dark'];
	 */
	const showToast = (text: string, variant: string) => {
		console.log('show toast');
		setToast({ text, variant });
	};

	const hideToast = () => {
		setToast(null);
	};

	return (
		<ToastContext.Provider value={{ showToast }}>
			{children}
			{toast && <ToastAlert text={toast.text} show={true} onClose={hideToast} variant={toast.variant} />}
		</ToastContext.Provider>
	);
};
