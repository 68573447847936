import React from 'react';
import { Link } from 'react-router-dom';
import WhatWeDoImage from '../../../assets/images/what-we-do.png';

const WhatWeDo: React.FC = () => {
	const whatWeDoList = [
		{ text: 'Comprehensive management solutions for Health, Education, Hospitality, Logistics, and more.' },
		{ text: 'Advanced security with strong encryption, multi-factor authentication, and compliance standards.' },
		{ text: 'Highly scalable and robust architecture designed for seamless growth and adaptability.' },
		{ text: 'Microservices-based architecture ensuring flexibility, maintainability, and rapid deployment.' },
		{ text: 'AI-enabled insights for predictive analytics, automation, and enhanced decision-making.' },
	];
	return (
		<>
			<section className="section-full bg-light">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-4 col-md-6 col-12">
							<div
								className="position-relative rellax mb-7 mb-lg-0"
								data-rellax-percentage="1"
								data-rellax-speed="0.8"
								data-disable-parallax-down="md"
							>
								<figure>
									<img src={WhatWeDoImage} alt="what we do" className="img-fluid" />
								</figure>
							</div>
						</div>
						<div className="col-lg-6 offset-lg-1 col-md-6 col-12">
							<div className="section-title">
								<p className="title-small">What we do</p>
								<h2 className="title-text">Comprehensive Management for Modern Businesses</h2>
								<p className="title-desc-text">
									Our platform offers an all-in-one solution for managing diverse industries, ensuring security,
									scalability, and operational excellence using the latest technologies.
								</p>
							</div>
							<ul className="list-unstyled mb-5">
								{whatWeDoList.map((item, index) => (
									<li className="mb-2 d-flex" key={index}>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="16"
											height="16"
											fill="currentColor"
											className="bi bi-check-circle text-primary mt-1"
											viewBox="0 0 16 16"
										>
											<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
											<path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
										</svg>
										<span className="ms-2">{item.text}</span>
									</li>
								))}
							</ul>
							<Link to="/help-and-support">
								Connect with us{' '}
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="14"
									height="14"
									fill="currentColor"
									className="bi bi-arrow-right"
									viewBox="0 0 16 16"
								>
									<path
										fillRule="evenodd"
										d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
									/>
								</svg>
							</Link>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default WhatWeDo;
