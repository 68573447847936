import { createBrowserRouter } from 'react-router-dom';
import AppLayout from './components/layout';
import ErrorPage from './pages/error/error';
import Contact from './pages/help-and-support/contact';
import Home from './pages/home';
import PrivacyPolicy from './pages/privacy-policy';
import Security from './pages/security';
import TermsAndConditions from './pages/terms-and-condition';
import WhatWeThink from './pages/what-we-think';
import WhoWeAre from './pages/who-we-are';

const routes = createBrowserRouter([
	{
		id: 'root',
		path: '/',
		errorElement: <ErrorPage />,
		element: <AppLayout />,
		children: [
			{
				index: true,
				element: <Home />,
			},
			{
				path: 'what-we-do',
				element: <Home />,
			},
			{
				path: 'who-we-are',
				element: <WhoWeAre />,
			},
			{
				path: 'what-we-think',
				element: <WhatWeThink />,
			},
			{
				path: 'help-and-support',
				element: <Contact />,
			},
			// {
			// 	path: 'careers',
			// 	element: <Careers />,
			// },
			// {
			// 	path: 'apply-job',
			// 	element: <ApplyJob />,
			// },
			{
				path: 'privacy-policy',
				element: <PrivacyPolicy />,
			},
			{
				path: 'terms-and-conditions',
				element: <TermsAndConditions />,
			},
			{
				path: 'security',
				element: <Security />,
			},
		],
	},
]);

export default routes;
