import React from 'react';
import { Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import InstagramLogo from '../../../assets/images/social/instagram.png';
import LinkedinLogo from '../../../assets/images/social/linkedin.png';
import TwitterLogo from '../../../assets/images/social/twitter.png';
import YoutubeLogo from '../../../assets/images/social/youtube.png';
import PhoneLink from '../../elements/phone-link';
import AppIcon from '../../icons';

// Updated footerLinks with path and label
const footerLinks = {
	company: [
		{ label: 'About Us', path: '/who-we-are' },
		{ label: 'Terms & Conditions', path: '/terms-and-conditions' },
		{ label: 'Privacy Policy', path: '/privacy-policy' },
		{ label: 'Security', path: '/security' },
	],
	services: ['Education', 'E-Commerce', 'Healthcare', 'Finance'],
	support: ['Docs', 'Support', 'Changelog', 'Help Center'],
};

type SocialLink = {
	src: string;
	alt: string;
	width: number;
	height: number;
	url: string;
};

const socialLinks: SocialLink[] = [
	{ src: TwitterLogo, alt: 'Twitter', width: 14, height: 14, url: 'https://x.com/anantixia' },
	{
		src: LinkedinLogo,
		alt: 'LinkedIn',
		width: 16,
		height: 16,
		url: 'https://www.linkedin.com/company/anantixia-llp/about/',
	},
	{ src: InstagramLogo, alt: 'Instagram', width: 16, height: 16, url: 'https://www.instagram.com/anantixia/' },
	{ src: YoutubeLogo, alt: 'YouTube', width: 16, height: 16, url: 'https://www.youtube.com/@anantixia' },
];

type FooterSectionProps = {
	title: string;
	links: { label: string; path: string }[]; // Updated links type
	isTranslatable?: boolean;
};

const FooterSection: React.FC<FooterSectionProps> = ({ title, links, isTranslatable }) => {
	const { t } = useTranslation();
	return (
		<Col lg={3} xs={12}>
			<ul className="footerNavWrap list-unstyled">
				{links.map((link, index) => (
					<li key={index}>
						<NavLink to={link.path}>{isTranslatable ? t(`layout:footer.${link.label}`) : link.label}</NavLink>
					</li>
				))}
			</ul>
		</Col>
	);
};

const Footer: React.FC = () => {
	const { t } = useTranslation();
	return (
		<footer className="footerWrap">
			<div className="container">
				<div className="row">
					<div className="col-lg-3">
						<div className="footer-col mb-3 mb-lg-0">
							<div className="d-flex mb-1">
								<div>
									<AppIcon name="MapPinIcon" width={16} height={16} />
								</div>
								<div className="ms-2">
									<p className="mb-0">
										845/7 Urban Enclave, Sector 7,
										<br /> Gurgaon - 122001,
										<br /> Haryana, India
									</p>
								</div>
							</div>
							<div className="d-flex mb-1`">
								<div>
									<AppIcon name="PhoneIcon" width={16} height={16} />
								</div>
								<div className="ms-2">
									<div className="mb-0 d-flex flex-wrap gap-2">
										<PhoneLink phoneNumber="+91 8745071220" phoneLinkText="+91 8745071220" /> |
										<PhoneLink phoneNumber="+91 9205384228" phoneLinkText="+91 9205384228" />
									</div>
								</div>
							</div>
						</div>
					</div>
					<FooterSection title="" links={footerLinks.company} />
					<div className="col-lg-3">
						<div>
							<p>
								Our platform offers an all-in-one solution for managing diverse industries, ensuring security,
								scalability, and operational excellence using the latest technologies.
							</p>
						</div>
					</div>
					<div className="col-lg-2 offset-lg-1">
						<ul className="list-inline p-0 mt-2 d-flex gap-3 social-icons-list">
							{socialLinks.map((link, index) => (
								<li key={index}>
									<NavLink to={link.url} target="_blank">
										<img src={link.src} alt={link.alt} width={link.width} height={link.height} />
									</NavLink>
								</li>
							))}
						</ul>
						<p className="copyrightText">{t('layout:footer.copyright')}</p>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
