import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const PrivacyPolicy: React.FC = () => {
	return (
		<section className="section-full">
			<Container>
				<Row className="justify-content-md-center">
					<Col xs={12} lg={8} className="text-center">
						<div className="section-title">
							<h2 className="title-text">Privacy Policy</h2>
							<p className="title-desc-text">
								Your privacy matters to us. Learn how we collect, use, and protect your personal data.
							</p>
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12} className="mb-4">
						<h6>Introduction</h6>
						<p>
							At Anantixia, your privacy is of utmost importance to us. We are committed to protecting your personal
							information and ensuring transparency in how we handle it. This Privacy Policy provides detailed
							information about the types of data we collect, the reasons for collecting it, how it is stored, and your
							rights regarding your personal information.
						</p>
						<p>
							Our goal is to ensure that you have a safe and trustworthy experience while using our platform. By using
							our services, you agree to the practices described in this Privacy Policy. We encourage you to read this
							document carefully and reach out to us if you have any concerns or questions about how your data is
							managed.
						</p>
					</Col>

					<Col xs={12} className="mb-4">
						<h6>What Data We Collect</h6>
						<p>
							We collect various types of information to ensure that we can provide you with the best possible
							experience on our platform. The data we collect includes:
						</p>
						<ul>
							<li>
								<strong>Personal Identification Information:</strong> This includes details such as your full name,
								email address, phone number, mailing address, and other information that identifies you directly.
							</li>
							<li>
								<strong>Professional Information:</strong> If applicable, we may collect details about your
								organization, job title, industry, and professional interests to provide relevant services.
							</li>
							<li>
								<strong>Technical Information:</strong> We automatically collect data such as your IP address, browser
								type, device information, and operating system when you visit our platform. This helps us analyze user
								behavior and improve our services.
							</li>
							<li>
								<strong>Cookies and Usage Data:</strong> We use cookies to understand how you interact with our
								platform, such as the pages you visit, the time spent on each page, and the links you click. This allows
								us to enhance your experience and provide personalized recommendations.
							</li>
							<li>
								<strong>Communication Data:</strong> Information you provide when you contact us through forms, emails,
								or customer support channels. This may include queries, feedback, or other messages.
							</li>
							<li>
								<strong>Payment Information:</strong> If applicable, we may collect payment-related details such as
								billing address and transaction history to process your orders or subscriptions securely.
							</li>
						</ul>
					</Col>

					<Col xs={12} className="mb-4">
						<h6>How We Use Your Data</h6>
						<p>
							Your data is used to ensure the seamless functioning of our platform and to provide you with the best
							possible experience. Specifically, we use your information for the following purposes:
						</p>
						<ul>
							<li>
								<strong>Service Delivery:</strong> To process your requests, fulfill orders, and ensure that you receive
								the services or products you have requested.
							</li>
							<li>
								<strong>Improving Our Platform:</strong> We analyze user behavior and feedback to identify areas for
								improvement, optimize user experiences, and develop new features.
							</li>
							<li>
								<strong>Personalized Communication:</strong> To send you updates, promotional content, or information
								that is relevant to your interests, based on your interactions with our platform. You can opt-out of
								marketing communications at any time.
							</li>
							<li>
								<strong>Customer Support:</strong> To respond to your queries, troubleshoot issues, and provide timely
								assistance.
							</li>
							<li>
								<strong>Security and Fraud Prevention:</strong> To ensure the safety of your data, prevent unauthorized
								access, and protect our platform from malicious activities.
							</li>
							<li>
								<strong>Legal Compliance:</strong> To comply with applicable laws and regulations, such as fulfilling
								legal requests from government authorities or ensuring compliance with data protection standards.
							</li>
						</ul>
					</Col>

					<Col xs={12} className="mb-4">
						<h6>Your Rights</h6>
						<p>
							We respect your rights regarding your personal information and aim to provide you with control over how
							your data is used. Below are the key rights you have as a user:
						</p>
						<ul>
							<li>
								<strong>Access to Your Data:</strong> You have the right to request a copy of the personal information
								we hold about you. This helps you understand what data we have collected and how it is being used.
							</li>
							<li>
								<strong>Correction of Inaccurate Data:</strong> If any of the information we have about you is incorrect
								or incomplete, you can request corrections. Ensuring the accuracy of your data is important to us.
							</li>
							<li>
								<strong>Data Deletion:</strong> You can request the deletion of your personal information from our
								systems. Please note that some data may need to be retained to comply with legal obligations or for
								legitimate business purposes.
							</li>
							<li>
								<strong>Data Portability:</strong> In certain cases, you can request a copy of your personal data in a
								structured, machine-readable format to transfer it to another service provider.
							</li>
							<li>
								<strong>Right to Object:</strong> You can object to the processing of your data for specific purposes,
								such as direct marketing. Upon your objection, we will cease processing your data for the specified
								purpose.
							</li>
							<li>
								<strong>Withdrawal of Consent:</strong> If you have previously provided consent for data processing, you
								have the right to withdraw it at any time. This will not affect the legality of data processing
								conducted before the withdrawal.
							</li>
							<li>
								<strong>Filing Complaints:</strong> If you believe your rights have been violated, you have the right to
								file a complaint with the relevant data protection authority in your jurisdiction.
							</li>
						</ul>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default PrivacyPolicy;
