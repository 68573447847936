import axios, { AxiosError, AxiosResponse } from 'axios';
import React, { ReactNode, useEffect, useState } from 'react';
import ToastAlert from '../../elements/toast-alert';

interface ToastProps {
	children: ReactNode;
}
interface ErrorResponse {
	message: string;
	code: string;
	timestamp: string;
	// Add other properties if present in your error response
}

const AxiosInterceptorProvider: React.FC<ToastProps> = ({ children }) => {
	const [showToast, setShowToast] = useState<boolean>(false);
	const [toastMessage, setToastMessage] = useState<string>('');
	const [toastVariant, setToastVariant] = useState<string>('Light');

	useEffect(() => {
		const interceptor = axios.interceptors.response.use(
			(response: AxiosResponse) => {
				return response;
			},
			(error: AxiosError) => {
				const errorMessage: string = getErrorMessage(error);
				setToastMessage(errorMessage);
				setToastVariant('Danger');
				setShowToast(true);
				return Promise.reject(error);
			}
		);

		return () => {
			axios.interceptors.response.eject(interceptor);
		};
	}, []);

	const handleCloseToast = () => {
		setShowToast(false);
	};

	const getErrorMessage = (error: AxiosError): string => {
		if (error.response) {
			const responseData = error.response.data as ErrorResponse;
			const statusErrorMap: { [key: number]: string } = {
				400: responseData.message || 'An error occurred',
				404: responseData.message || 'Resource not found',
				406: responseData.message || 'Resource not acceptable',
				422: responseData.message || 'Unprocessable entity',
				500: responseData.message || 'Internal server error',
			};
			const errorMessage: string = statusErrorMap[error.response.status] || 'An error occurred';
			return errorMessage;
		} else if (error.request) {
			return error.message || 'The request was made but no response was received';
		} else {
			return error.message || 'Something happened in setting up the request that triggered an error';
		}
	};

	return (
		<>
			{showToast && (
				<ToastAlert text={toastMessage} show={showToast} onClose={handleCloseToast} variant={toastVariant} />
			)}
			{children}
		</>
	);
};

export default AxiosInterceptorProvider;
