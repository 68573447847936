import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Security: React.FC = () => {
	return (
		<section className="section-full">
			<Container>
				<Row className="justify-content-md-center">
					<Col xs={12} lg={8} className="text-center">
						<div className="section-title">
							<h2 className="title-text">Security</h2>
							<p className="title-desc-text">
								Your security is our top priority. We employ advanced technologies, implement best practices, and
								provide guidelines to protect your data and ensure a secure platform experience.
							</p>
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12} className="mb-4">
						<h6>Data Protection</h6>
						<p>
							We use cutting-edge encryption protocols to safeguard your personal and professional data both in transfer
							and at rest. Access to sensitive data is restricted through stringent role-based access control (RBAC)
							mechanisms, ensuring only authorized personnel can access critical information. All data exchanges between
							your browser and our platform are secured with HTTPS and TLS protocols to prevent unauthorized
							interception.
						</p>
					</Col>

					<Col xs={12} className="mb-4">
						<h6>Security Measures</h6>
						<p>
							Our platform undergoes regular monitoring, penetration testing, and vulnerability assessments to identify
							and address potential risks. We collaborate with external security experts to ensure our defenses are
							robust and comply with global industry standards like ISO 27001, SOC 2, and GDPR. To maintain a secure
							environment, our team implements automated patch management for all systems and applications.
						</p>
					</Col>

					<Col xs={12} className="mb-4">
						<h6>User Responsibilities</h6>
						<p>
							As a user, you play a critical role in maintaining the security of your account. We encourage you to use
							strong, unique passwords for your accounts and change them periodically. Two-factor authentication (2FA)
							is available to add an additional layer of security to your login process. Please avoid sharing your
							credentials with others, and ensure that you log out of your account after using shared devices. If you
							notice any unauthorized activity on your account, report it immediately to our support team for
							investigation.
						</p>
					</Col>

					<Col xs={12} className="mb-4">
						<h6>Incident Response</h6>
						<p>
							In the event of a security incident or data breach, we follow a comprehensive incident response plan to
							minimize potential damage and mitigate risks. Our dedicated security team promptly investigates the issue,
							notifies affected users, and provides actionable steps to protect your data. We ensure complete
							transparency during such events and take preventive actions to reduce the likelihood of future incidents.
						</p>
						<p>
							Our commitment to security includes regular training for our staff on the latest cybersecurity threats and
							best practices. We also maintain a 24/7 incident response team to handle emergencies efficiently.
						</p>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default Security;
