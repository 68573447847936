import React from 'react';

interface PhoneLinkProps {
	phoneNumber: string;
	phoneLinkText?: string;
}

const PhoneLink: React.FC<PhoneLinkProps> = ({ phoneNumber, phoneLinkText = 'Call' }) => {
	return (
		<div>
			<a className="link-text" href={`tel:${phoneNumber}`}>
				{phoneLinkText}
			</a>
		</div>
	);
};

export default PhoneLink;
