import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import EmailLink from '../../components/elements/email-link';

const TermsAndConditions: React.FC = () => {
	return (
		<section className="section-full">
			<Container>
				<Row className="justify-content-md-center">
					<Col xs={12} lg={8} className="text-center">
						<div className="section-title">
							<h2 className="title-text">Terms and Conditions</h2>
							<p className="title-desc-text">
								Please read our terms and conditions carefully. By using our platform, you agree to adhere to the terms
								outlined below, ensuring a fair and secure environment for all users.
							</p>
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12} className="mb-4">
						<h6>Acceptance of Terms</h6>
						<p>
							By accessing and using our platform, you acknowledge that you have read, understood, and agree to be bound
							by these terms and conditions. If you do not agree with any part of these terms, you must immediately
							discontinue using our services. These terms apply to all visitors, users, and others who access or use the
							platform.
						</p>
						<p>
							Your continued use of the platform constitutes your acceptance of any updates or modifications to these
							terms. We recommend reviewing this document periodically to stay informed about any changes.
						</p>
					</Col>

					<Col xs={12} className="mb-4">
						<h6>User Responsibilities</h6>
						<p>
							As a user of our platform, you are responsible for maintaining the confidentiality of your account
							credentials, including your username and password. You agree to notify us immediately of any unauthorized
							access or suspicious activity related to your account.
						</p>
						<p>
							You must use the platform in compliance with all applicable laws, rules, and regulations. This includes
							avoiding activities that could harm the platform, other users, or any third party. You are solely
							responsible for the content you upload, share, or distribute on our platform and must ensure that it does
							not violate any laws or infringe on the rights of others.
						</p>
					</Col>

					<Col xs={12} className="mb-4">
						<h6>Prohibited Activities</h6>
						<p>
							To maintain the integrity and security of our platform, the following activities are strictly prohibited:
						</p>
						<ul>
							<li>Attempting to gain unauthorized access to the platform or its systems.</li>
							<li>Engaging in activities that could disrupt the functionality of the platform.</li>
							<li>
								Uploading or distributing harmful content, including malware, viruses, or any other malicious software.
							</li>
							<li>
								Using the platform for unlawful purposes, such as fraud, identity theft, or any activity that violates
								applicable laws.
							</li>
							<li>Attempting to reverse-engineer, decompile, or otherwise extract the source code of our platform.</li>
						</ul>
						<p>
							Violations of these terms may result in the immediate suspension or termination of your account, along
							with potential legal action.
						</p>
					</Col>

					<Col xs={12} className="mb-4">
						<h6>Changes to Terms</h6>
						<p>
							We reserve the right to update or modify these terms and conditions at any time to reflect changes in our
							services, legal obligations, or business practices. Any changes will be effective immediately upon posting
							on this page.
						</p>
						<p>
							It is your responsibility to review these terms periodically to ensure that you are aware of and comply
							with any updates. Continued use of the platform after changes are posted constitutes your acceptance of
							the revised terms.
						</p>
						<p>
							If you have any questions or concerns about these terms, please contact our support team at{' '}
							<EmailLink emailId="support@anantixia.com" emailLinkText="support@anantixia.com" />
						</p>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default TermsAndConditions;
